import { configureStore } from '@reduxjs/toolkit'
import ordersSlice from '../orders/ordersSlice'
import personaliseSlice from '../cards/personalise/personaliseSlice'
import staticDataSlice from './staticDataSlice'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import logger from 'redux-logger'
import { thunk } from 'redux-thunk'
import authSlice from '../auth/authSlice'
import cardVrViewSlice from '../cards/vrview/cardVrViewSlice'
import featuresSlice from '../features/featuresSlice';
import notificationSlice from './notificationSlice';
import cookiesSlice from '../cookies/cookiesSlice';
import orderViewSlice from '../pages/orders/orderViewSlice';

const persistConfig = {
  key: 'peacolo-root',
  storage,
}

const rootReducer = combineReducers({
  authSlice,
  ordersSlice,
  personaliseSlice,
  cardVrViewSlice,
  staticDataSlice,
  featuresSlice,
  notificationSlice,
  cookiesSlice,
  orderViewSlice,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleware = [thunk]
// const middleware = [logger, thunk]

export const reduxStore = configureStore({
  reducer: persistedReducer,
  middleware: middleware,
  devTools: process.env.NODE_ENV !== 'production',

})

export const reduxPersistor = persistStore(reduxStore)

// export const reduxStore = configureStore({
//     reducer: {
//         ordersSlice,
//         personaliseSlice,
//         staticDataSlice
//     }
// })